<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images" :class="{'one-image': collage.length === 1, 'three-images': collage.length === 3}">
          <div v-for="image in collage" :key="image.title" :style="{backgroundImage:`url(${image.file})`}" />
      </article>        
      </transition>      
      <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
      </div>       
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import LinearButton from '@/components/LinearButton';
export default {
    name:'Up to Three Images No Text',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{            
        LinearButton,
    },
    data() {
        return {
            imagesFromGallery:2,    
        }
    },
   computed: {
        collage() {
            let images = [];
            if(this.page.background) {
                images.push({
                    file:this.page.background,
                    title:this.page.title
                });
            }
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },                 
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .template > .linear-holder {
            display: none;
            position: relative;
            height:90px;
        }
    }  
    .copy-holder > .linear-holder {
        position: relative;
        padding-top:16px;
        .linear {
            position: static;
        }
        @media (min-width:700px) {  
            display: none;
        }
    }             
    .images {        
        display:grid;
        gap:1px;        
        height:100%; 
        min-height:50vh;
        background: #F5F5F5;
        overflow: hidden;       
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;            
            background-repeat: no-repeat;            
        }
        &.one-image {
            background-color: #000;
            > div{
            background-size: contain;
            background-repeat: no-repeat;
            }
        }
        &.three-images {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 50% 50%;
            > div {  
                position: relative;              
                &:last-child {
                    justify-content: flex-end;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    grid-column-start: 2;
                    grid-column-end: 2;
                }
            }
        }             
    }
</style>